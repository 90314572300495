import React from 'react';
import {BASKET_PATH, EDIT_ORDER_PATH, HISTORY_PATH} from 'constants/routes';
import TitleBar from 'components/Navigation/TitleBar';
import {useTranslate} from 'helpers/translate';
import Container from 'components/Container';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {path, prop} from 'ramda';
import Mapper from 'components/Mapper';
import ListCard from 'components/ListCard';
import Description from 'components/Description';
import formatNumber from 'helpers/formatNumber';
import styled from 'styled-components';
import dateTimeFormat from 'helpers/dateTimeFormat';
import mapIndexed from 'helpers/mapIndexed';
import storage from 'helpers/storage';
import {parseParams} from 'helpers/url';
import {historyItemConfirm} from 'actions/history';
import {useDispatch} from 'react-redux';
import sprintf from 'sprintf'
import {getStatus} from "../../Grid/HistoryGrid";

const Total = styled('div')`
    margin-top: 10px;
    background: white;

    & > :not(:last-child) {
        border-bottom: 1px solid rgb(228, 228, 230);
    }

    & > :not(:first-child) {
        padding-top: 10px;
    }

    & > :not(:last-child) {
        padding-bottom: 10px;
    }

    padding: 15px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
    border-radius: 10px;
`

const Fixed = styled('div')`
    position: fixed;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 55px;
    background: ${({background}) => background || 'linear-gradient(180deg, #4c956c 0%, #4c956c 100%)'};
    display: flex;
    align-items: center;
    justify-content: ${({position}) => position || 'center'};
    padding: 0 16px;
    color: white;
    ${({styles}) => styles}
`

const Button = styled('button')`
    color: ${({theme}) => theme.palette.white};
    font-weight: 500;
    font-size: 17px;
    padding: 14px 0;
    background: transparent;
    border-radius: 8px;
    width: 49%;
    border: none;
    text-align: center;
    background: ${({background}) => background || 'linear-gradient(180deg, #4c956c 0%, #4c956c 100%)'};
    outline: none !important;

    &:disabled {
        color: inherit;
    }
`


const styles = {
  wrapper: {
    marginTop: '5px'
  },
  button: {
    marginTop: '15px'
  },
  parent: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: "#333333"
  },
  child: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    color: '#4c956c'
  }
}

const HistoryDetailGrid = (props) => {
  const {id, detail} = props;

  const {t} = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = prop('location', history);
  const search = prop('search', location);
  const searchObj = parseParams(search);
  const data = prop('data', detail);
  const products = prop('products', data)
  const status = prop('status', searchObj)
  const currency = path(['currency', 'sign'], data)
  const loading = prop('loading', detail)
  const total = prop('totalPrice', data)
  const createdDate = prop('createdDate', data)
  const statusData = path(['status'], data) ? getStatus(+path(['status'], data)) : {}

  const handleDublicate = () => {
    const formatProducts = products ? mapIndexed((item) => {
      const newPrice = prop('newPrice', item)
      const cashPrice = prop('cashPrice', item)
      const productId = prop('productId', item)
      const orderProductId = prop('id', item)
      return {
        ...item,
        cashPrice: newPrice || 0,
        oldPrice: cashPrice,
        id: productId,
        orderProductId,
        currency: prop('currency', data)
      }
    }, products) : []
    storage('BASKET').set(formatProducts)
    history.push({
      pathname: BASKET_PATH
    })
  }

  const handleEditOrder = () => {
    history.push({
      pathname: sprintf(EDIT_ORDER_PATH, Number(id))
    })
  }

  const handleConfirm = () => {
    dispatch(historyItemConfirm(id)).then(() => {
      history.push({
        pathname: HISTORY_PATH,
        search: '?status=pending'
      })
    })
  }

  const content = (
    <>
      <Total>
        <Description
          withOutMargin
          parent={`${t('order_price')}:`}
          child={formatNumber(total, currency, ' ')}
          parentStyles={styles.parent}
          childStyles={styles.child}
        />
        <Description
          withOutMargin
          parent={`${t('created_date')}:`}
          child={dateTimeFormat(createdDate)}
          parentStyles={styles.parent}
          childStyles={{...styles.child, color: '#252325'}}
        />
        <Description
          withOutMargin
          parent={`${t('status')}:`}
          child={statusData.text}
          parentStyles={styles.parent}
          childStyles={{...styles.child, color: statusData.color}}
        />
      </Total>
    </>
  )
  return (
    <>
      <TitleBar
        background={'inherit'}
        title={t('order') + id}
        icon={'back'}
        border={'1px solid #DFE0E3'}
        onClick={() => {
          history.push({
            pathname: HISTORY_PATH,
            search
          })
        }}
      />
      <Container
        hasFixedBar
        hasStatusBar
      >
        <Mapper
          content={content}
          data={products}
          loading={loading}
          item={ListCard}
          itemProps={{
            customCurrency: currency,
            'isOrder': true
          }}
        />
        {!status ?
          <Fixed onClick={handleDublicate}>
            Дублировать заказ
          </Fixed> :
          <Fixed
            styles={{
              paddingBottom: '15px'
            }}
            background={'transparent'} position={'space-between'}>
            <Button onClick={handleConfirm} background={'rgb(41, 52, 208)'}>
              Подтвердить
            </Button>
            <Button onClick={handleEditOrder}>
              Изменить
            </Button>
          </Fixed>
        }
      </Container>
    </>
  );
};

HistoryDetailGrid.propTypes = {
  detail: PropTypes.object.isRequired,
  id: PropTypes.any.isRequired
}

export default HistoryDetailGrid;

const MAIN = 'main'
export const PRODUCTS_LIST = `/${MAIN}/product_shop/`
export const PRODUCT_ITEM = `/${MAIN}/product_shop/%d/`
export const BRANDS_LIST = `/${MAIN}/brand_shop/`
export const HISTORY_LIST = '/sales/order/shop_list/'
export const HISTORY_ITEM = '/sales/order/%d/shop_detail/'
export const SHOP_CHANGE = '/sales/order/%d/shop_change/'
export const CHECK_AUTH = '/telegram/users/check_auth/'
export const SEND_CONFIRM = '/telegram/users/send_confirm_sms/'
export const CONFIRM_PHONE = '/telegram/users/confirm_phone/'
export const GET_ADDRESS = '/telegram/user_address/get_address/'
export const CREATE_ADDRESS = '/telegram/user_address/add_address/'
export const DELETE_ADDRESS = '/telegram/user_address/delete_address/'
export const CREATE_ORDER = '/telegram/order/web_create/'


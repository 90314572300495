import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {X as ExitIcon} from "react-feather";
import PropTypes from "prop-types";
import {Form as FinalForm} from 'react-final-form'
import Button from "../Navigation/Components/Button";
import VerificationCode, {vertificationFields} from "./components/VerificationCode/VerificationCode";
import useCreate from "../../hooks/useCreate";
import Auth from "./components/Auth/Auth";
import useFetch from "../../hooks/useFetch";
import * as STATES from "../../redux/reducers/states";
import {confirmPhoneCreateAction, sendConfirmCreateAction, userCheckCreateAction} from "../../actions/user";
import {getSerializedData} from "../../helpers/get";
import Adress from "./components/Adress/Adress";
import {orderCreateAction} from "../../actions/order";
import Loader from "../Loader";
import {useDispatch} from "react-redux";
import {useTranslate} from 'helpers/translate';
import * as ACTION_TYPES from "../../redux/types";

const BGMusk = styled('div')`
    background: ${({theme}) => theme.background.mask};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: ${({isOpen}) => isOpen ? '1' : '0'};
    transition: all .2s ease-out;
    visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
    z-index: 111;
    margin: 0 !important;
`
const ModalContent = styled('div')`
    background: #fff;
    border-radius: 12px;
    padding: 18px 16px;
    display: ${({isOpen}) => isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    transition: all .2s ease-out;
    transform: translateX(-50%) translateY(-60%) !important;
    max-width: 375px;
    width: 95%;
    z-index: 112;
    max-height: 65vh;
`
const Title = styled('h4')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: ${({theme}) => theme.palette.black};

    h2 {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
    }
`
const TitleWrap = styled('div')`
    min-height: 35px;
    border-bottom: 1px;
    display: flex;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(247 247 247);
`

const ExitIconWrap = styled('span')`
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #e6e6e7;

    svg {
        color: rgba(130, 130, 130, 0.85);
    }
`

const BodyWrap = styled('div')`
    height: 100%;
    overflow: auto;
`

const InContent = styled('div')`
    min-height: 200px;
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const TabWrap = styled('div')`
    overflow-x: ${({value, show}) => value !== show && 'hidden'};
    overflow-y: ${({value, show}) => value !== show ? 'hidden' : 'auto'};
    height: ${({value, show, height}) => value !== show ? '0' : (height || '100%')};
    margin: ${({value, show}) => value !== show && '0'};
    padding: ${({value, show}) => value !== show && '0'};

    & button {
        display: ${({value, show}) => value !== show && 'none'};
    }
`

const orderFields = [
  'userAddress'
]

const ModalCheckout = (props) => {
  const {
    open,
    setOpenAddress,
    products,
    onClose,
    refetch,
    setDetail
  } = props
  const {t} = useTranslate()
  const [value, setValue] = useState(0)
  const dispatch = useDispatch()
  const checkAuth = useFetch({
    action: userCheckCreateAction,
    stateName: STATES.CHECK_AUTH,
    childIsNull: true
  })
  const sendConfirm = useCreate({
    action: sendConfirmCreateAction,
    onSuccess: () => {
      setValue(1)
    },
    serializer: (data) => ({phone: '+998' + (data.phone || '')}),
    stateName: STATES.SEND_CONFIRM,
    childIsNull: true
  })

  const confirmPhone = useCreate({
    action: confirmPhoneCreateAction,
    onSuccess: () => {
      setValue(2)
      dispatch({
        type: `${ACTION_TYPES.CHECK_AUTH}_FULFILLED`,
        payload: {
          isAuthenticated: true
        }
      })
    },
    serializer: (values) => ({...getSerializedData(vertificationFields, values), phone: '+998' + (values.phone || '')}),
    stateName: STATES.CONFIRM_PHONE,
    childIsNull: true
  })

  const orderCreate = useCreate({
    action: orderCreateAction,
    onSuccess: () => {
      setValue(2)
      onClose()
    },
    serializer: (values) => ({products, ...getSerializedData(orderFields, values)}),
    stateName: STATES.ORDER_CREATE,
    childIsNull: true
  })

  const isAuthenticated = checkAuth?.data?.isAuthenticated

  // useEffect
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [open])
  return (
    <>
      <BGMusk isOpen={open} onClick={() => {
        onClose()
        setValue(0)
      }}/>
      <ModalContent isOpen={open}>
        <TitleWrap>
          <Title>{isAuthenticated ? t('making_order') : t('authorization')}</Title>
          <ExitIconWrap onClick={() => {
            onClose()
            setValue(0)
          }}>
            <ExitIcon size={16}/>
          </ExitIconWrap>
        </TitleWrap>
        <BodyWrap>
          {checkAuth.loading ? (
            <Loader/>
          ) : (
            <FinalForm
              onSubmit={(values) => {
                if (isAuthenticated) {
                  return orderCreate.onSubmit({products, ...values})
                } else if (value === 1) {
                  return confirmPhone.onSubmit(values)
                } else {
                  return sendConfirm.onSubmit(values)
                }
              }}
              keepDirtyOnReinitialize={true}
              render={({handleSubmit, values, form, ...formProps}) => {
                return (
                  <form onSubmit={handleSubmit} style={{height: '100%'}}>
                    <InContent>
                      <TabWrap
                        value={value}
                        show={0}
                      >
                        {isAuthenticated ? <Adress form={form} setOpenAddress={setOpenAddress} refetch={refetch} setDetail={setDetail}/> : <Auth/>}
                      </TabWrap>
                      <TabWrap
                        value={value}
                        show={1}
                      >
                        {value === 1 && (
                          <VerificationCode
                            values={values}
                            sendConfirm={sendConfirm}
                            setValue={setValue}
                          />
                        )}
                      </TabWrap>
                      <TabWrap
                        value={value}
                        show={2}
                      >
                        {value === 2 && (
                          <Adress
                            form={form}
                            values={values}
                            setOpenAddress={setOpenAddress}
                            refetch={refetch}
                            setDetail={setDetail}
                          />
                        )}
                      </TabWrap>
                      <Button
                        style={{
                          marginTop: '10px'
                        }}
                        type={'submit'}
                        loading={sendConfirm.loading || confirmPhone.loading || orderCreate.loading}
                        disabled={sendConfirm.loading || confirmPhone.loading || orderCreate.loading}
                        background={'#4c956c'}
                        color={'#ffffff'}
                        height={'45px'}
                        title={isAuthenticated ? t('save') : t('continue')}
                      />
                    </InContent>
                  </form>
                )
              }}
            />
          )}
        </BodyWrap>
      </ModalContent>
    </>
  )
}

ModalCheckout.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ModalCheckout

export const MAIN_PRODUCTS_LIST_FETCH_ACTION = 'MAIN_PRODUCTS_LIST_FETCH_ACTION'
export const MAIN_PRODUCTS_ITEM_FETCH_ACTION = 'MAIN_PRODUCTS_ITEM_FETCH_ACTION'
export const BRANDS_LIST_FETCH_ACTION = 'BRANDS_LIST_FETCH_ACTION'
export const HISTORY_LIST_FETCH_ACTION = 'BRANDS_LIST_FETCH_ACTION'
export const HISTORY_ITEM_FETCH_ACTION = 'BRANDS_LIST_FETCH_ACTION'
export const SHOP_CHANGE_ACTION = 'SHOP_CHANGE_ACTION'
export const ADD_TO_BASKET_ACTION = 'ADD_TO_BASKET_ACTION'
export const ORDER_CREATE_ACTION = 'ORDER_CREATE_ACTION'
export const CHECK_AUTH = 'CHECK_AUTH'
export const SEND_CONFIRM = 'SEND_CONFIRM'
export const GET_ADDRESS = 'GET_ADDRESS'
export const CREATE_ADDRESS = 'CREATE_ADDRESS'
export const DELETE_ADDRESS = 'DELETE_ADDRESS'
export const CONFIRM_PHONE = 'CONFIRM_PHONE'

// Error
export const ERROR = 'ERROR'
export const ERROR_OPEN = `${ERROR}_OPEN`
export const ERROR_CLOSE = `${ERROR}_CLOSE`

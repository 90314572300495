import React, {useState, useEffect} from 'react';
import AddressField from "./AddressField";
import YandexMap from "../../Fields/Map/YandexMap";
import {isEmpty, path, prop} from "ramda";
import Modal from "../../Modal/Modal";
import styled from "styled-components";
import {Form as FinalForm} from 'react-final-form'
import Fields from "../../Fields/Fields";
import useCreate from "../../../hooks/useCreate";
import {getSerializedData} from "../../../helpers/get";
import * as STATES from "../../../redux/reducers/states";
import {addressCreateAction, addressDeleteAction} from "../../../actions/adress";
import {useTranslate} from "../../../helpers/translate";


const Container = styled.div`
    height: ${({height}) => height || '50vh'};
    overflow: auto;
    padding: 15px 8px;

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    scrollbar-gutter: stable both-edges;
`
const addressFields = [
  'location',
  'name',
  'building',
  'entrance',
  'floor',
  'room',
  'comment',
]
const FieldsAdres = ({fields, step, setStep, loading, isDetail}) => {
  const addressInputs = prop('address', fields)
  const locationInputs = prop('location', fields)
  const onAddressChange = addressInputs.input.onChange
  const addressValue = addressInputs.input.value

  return (
    <Container height={step === 1 || isDetail ? '50vh' : '80vh'}>
      {step === 1 || isDetail ? (
        <AddressField
          name={'address'}
          loading={loading}
          input={addressInputs.input}
          locationInputs={locationInputs.input}
          onIconClick={() => setStep(0)}
          isDetail={isDetail}
        />
      ) : (
        <YandexMap
          {...locationInputs}
          onToggle={() => setStep(1)}
          addressValue={addressValue}
          onAddressChange={onAddressChange}
        />
      )}
    </Container>
  )
}

const FieldsContent = ({step, setStep, isDetail, deleteAddress, createAddress, form, handleSubmit}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const addressElement = document.querySelector('.sc-afnQL');

      if (addressElement) {
        form.change('name',  addressElement.textContent);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [form]);

  return (
    <form onSubmit={handleSubmit} style={{height: '100%'}}>
      <Fields
        names={[
          'location',
          'address',
        ]}
      >
        {(fields) => {
          return <FieldsAdres
            fields={fields}
            step={step}
            setStep={setStep}
            loading={isDetail ? deleteAddress.loading : createAddress.loading}
            isDetail={isDetail}
          />
        }}
      </Fields>
    </form>
  )
}

const AddAddressModal = ({open, onClose, onSuccess, detail}) => {
  const {t} = useTranslate()
  const [step, setStep] = useState(0)

  const createAddress = useCreate({
    action: addressCreateAction,
    onSuccess: () => {
      setStep(0)
      onClose()
      onSuccess()
    },
    serializer: (values) => ({...getSerializedData(addressFields, values)}),
    stateName: STATES.CREATE_ADDRESS,
    childIsNull: true
  })

  const deleteAddress = useCreate({
    action: addressDeleteAction,
    onSuccess: () => {
      onClose()
      onSuccess()
    },
    serializer: () => ({user_address: path(['data', 'id'], detail)}),
    stateName: STATES.CREATE_ADDRESS,
    childIsNull: true
  })

  const isDetail = detail && !isEmpty(detail)
  const detailData = prop('data', detail)
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        maxWidth={'90%'}
        title={isDetail ? 'Детали' : t('indicate_map')}
        showHeader={true}
        modalStyles={{
          margin: 0,
          top: '50%',
          position: 'relative',
          transform: step === 1 || isDetail ? 'translateY(-40%) !important' : 'translateY(-30%) !important',
        }}
        bodyPadding={'0px'}
      >
        <FinalForm
          onSubmit={isDetail ? deleteAddress.onSubmit : createAddress.onSubmit}
          initialValues={{
            name: prop('name', detailData),
            entrance: prop('entrance', detailData),
            floor: prop('floor', detailData),
            room: prop('room', detailData),
            comment: prop('comment', detailData),
            location: prop('location', detailData),
          }}
          render={({handleSubmit, values, form, ...formProps}) => {
            return (
              <FieldsContent
                step={step}
                setStep={setStep}
                createAddress={createAddress}
                deleteAddress={deleteAddress}
                form={form}
                handleSubmit={handleSubmit}
              />
            )
          }}
        />
      </Modal>
    </>
  );
};

export default AddAddressModal;

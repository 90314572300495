import axios from 'helpers/axios';
import * as API from 'constants/api';
import {path, pathOr, prop} from 'ramda';
import * as ACTION_TYPES from 'redux/types';
import sprintf from 'sprintf';

export const historyListFetchAction = (page, showMore, status) => {
  const params = {
    'thumbnail_type': 'large',
    'page': page
  }
  const telegram = window.Telegram.WebApp.initDataUnsafe
  const telegram_auth = window.Telegram.WebApp.initData

  const store = window.store.getState()
  const list = showMore ? pathOr([], ['history', 'list', 'data', 'results'], store) : []

  const payload = axios()
    .post(API.HISTORY_LIST, {telegram, telegram_auth}, {params: {...params, status}})
    .then((response) => {
      return {
        ...path(['data'], response),
        results: [...list, ...path(['data', 'results'], response)]
      }
    })
    .catch((error) => {
      return Promise.reject(path(['response', 'data'], error))
    })

  return {
    type: ACTION_TYPES.HISTORY_LIST_FETCH_ACTION,
    payload
  }
}

export const historyItemFetchAction = (id) => {
  const telegram = window.Telegram.WebApp.initDataUnsafe
  const telegram_auth = window.Telegram.WebApp.initData
  const params = {
    'thumbnail_type': 'large',
  }
  const payload = axios()
    .post(sprintf(API.HISTORY_ITEM, Number(id)), {telegram, telegram_auth}, {params})
    .then((response) => {
      return Promise.resolve(path(['data'], response))
    })
    .catch((error) => {
      return Promise.reject(path(['response', 'data'], error))
    })

  return {
    type: ACTION_TYPES.HISTORY_ITEM_FETCH_ACTION,
    payload
  }
}

export const historyItemConfirm = (id, data = {}) => {
  const telegram = window.Telegram.WebApp.initDataUnsafe
  const payload = axios()
    .post(sprintf(API.SHOP_CHANGE, Number(id)), {telegram, confirm_order: 'True', ...data})
    .then((response) => {
      return Promise.resolve(path(['data'], response))
    })
    .catch((error) => {
      return Promise.reject(path(['response', 'data'], error))
    })

  return {
    type: ACTION_TYPES.SHOP_CHANGE_ACTION,
    payload
  }
}

import React from 'react';
import Container from 'components/Container';
import {HISTORY_ITEM_PATH, PROFILE_PATH} from 'constants/routes';
import TitleBar from 'components/Navigation/TitleBar';
import {useTranslate} from 'helpers/translate';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {isEmpty, path, pathOr} from 'ramda';
import dateTimeFormat from 'helpers/dateTimeFormat';
import formatNumber from 'helpers/formatNumber';
import Loader from 'components/Loader';
import Button from 'components/Navigation/Components/Button';
import Mapper from 'components/Mapper';
import sprintf from 'sprintf';

const List = styled('div')`
`

const Item = styled('div')`
  width: 100%;
  border-top: 1px solid #DFE0E3;

  &:last-child {
    border-bottom: 1px solid #DFE0E3;
  }

  padding: 14px 0;
`

const Flex = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.005em;
    color: #2E384C;
    mix-blend-mode: normal;
  }

  & > :last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 129.96%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.005em;
    color: #4c956c;
    mix-blend-mode: normal;
  }
`

const Date = styled('div')`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  letter-spacing: -0.005em;
  color: #69707E;
  mix-blend-mode: normal;
`

export const getStatus = (status) => {
  switch (status) {
    case 0:
      return {
        text: "Ожидает",
        color: '#f0ad4e'
      }
    case 1:
      return {
        text: "Ожидает",
        color: "#f0ad4e"
      }
    case 2:
      return {
        text: "Завершен",
        color: "#81c784"
      }
    case 3:
      return {
        text: "Отменен",
        color: '#e57373'
      }
    default:
      return null
  }
}

const HistoryGrid = (props) => {
  const {historyList, setPage, status, page, loading, moreLoading, hasMore} = props
    const {t} = useTranslate()
  const history = useHistory()
  const results = pathOr([], ['data', 'results'], historyList)
  return (
    <>
      <TitleBar
          background={'inherit'}
          title={status === 'pending' ? t('pending_orders') : t('my_orders')}
          icon={'back'}
          border={'1px solid #DFE0E3'}
          onClick={() => {
          history.push({
            pathname: PROFILE_PATH
          })
        }}
      />
      <Container
        hasStatusBar
      >
        <>
          {
            loading
              ? <Loader/>
              : <List>
                <Mapper
                  item={({data}) => {
                    const id = path(['id'], data)
                    const createdDate = path(['createdDate'], data)
                    const currency = path(['currency', 'sign'], data)
                    const totalPrice = path(['totalPrice'], data)
                    const statusData = getStatus(+path(['status'], data))
                    return (
                      <Item
                        onClick={() => {
                          history.push({
                              pathname: sprintf(HISTORY_ITEM_PATH, id),
                              search: status ? `?status=${status}` : ''
                          })
                        }}
                      >
                        <Flex>
                          <div>
                            <span>Заказ №{id}</span> · <span style={{color: statusData.color}}>{statusData.text}</span>
                            <Date>
                              {dateTimeFormat(createdDate)}
                            </Date>
                          </div>
                          <p>{formatNumber(totalPrice, currency, ' ')}</p>
                        </Flex>
                      </Item>
                    )
                  }}
                  data={results}
                  loading={loading}
                />
                {
                  loading || isEmpty(results) ?
                    null
                    : moreLoading
                      ? <Loader/>
                      : hasMore ? <Button
                        background={'rgb(41, 52, 208)'}
                        height={'45px'}
                        style={{
                          margin: '10px 0'
                        }}
                        title={'Показать больше'}
                        onClick={() => hasMore ? setPage(page + 1) : ''}
                      /> : null
                }
              </List>
          }
        </>
      </Container>
    </>
  )
}

HistoryGrid.propTypes = {
  historyList: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  moreLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired
}

export default HistoryGrid

import {equals, filter, isEmpty, map, pipe} from 'ramda'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {DEFAULT_PICK_PARAMS} from 'helpers/isEquals'
import useCompareEffect from 'hooks/useCompareEffect'
import {getDataFromState, getParams as get} from 'helpers/get'
import useHistoryObj from 'hooks/useHistoryObj';

const useFetch = (params) => {
  const {
    stateName,
    action,
    mapper = get,
    child,
    pickParams = DEFAULT_PICK_PARAMS,
    shallQuery,
    onSuccess = (res) => res,
    childIsNull,
    dependencies = [],
    dependency = null
  } = params
  const currentChild = childIsNull ? null : child || 'list'
  const dispatch = useDispatch()
  const history = useHistory()
  const {getParams} = useHistoryObj()
  const location = useLocation()

  const searchParams = getParams(history, pickParams)


  const data = useSelector(state => getDataFromState(stateName, currentChild, state), equals)
  useCompareEffect(() => {
    if (shallQuery) {
      const isHasQueries = pipe(
        map(i => {
          return !!searchParams[i]
        }),
        filter(i => i === false),
        isEmpty
      )(shallQuery)
      isHasQueries && dispatch(action(mapper(history, pickParams), dependencies, {...location, ...getParams()})).then(onSuccess)
    } else {
      dispatch(action(mapper(history, pickParams), dependencies, {...location, ...getParams()})).then(onSuccess)
    }

  }, [searchParams, dependency, ...dependencies])

  return data
}

export default useFetch

import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { is } from 'ramda'

const Text = styled.p`
  color: ${({ theme }) => theme.color.primary};
  font-weight: 700;
  user-select: none;
  font-size: 14px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Circle = styled.span`
    display: flex;
    height: 20px;
    background: #fff;
    width: 20px;
    border-radius: 100%;
    border: 1px solid #dadada;
`

const Label = styled('label')`
    width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  input {
    display: none;
    &:checked + div {
      border: 1px solid #2934d0;
      ${Circle} {
          border: 5px solid #2934d0;
      };
      ${Text} {
        color: ${({ theme }) => theme.palette.primary};
      }
    }
  }
`

const SubText = styled.p`
  color: #8f8f8f;
  margin-top: 12px;
  user-select: none;
  font-size: 13px;
`

const Payment = styled.div`
  overflow: hidden;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  position: relative;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  border: ${() => `1px solid transparent`};
  text-align: left;
  border-radius: 7px;
  padding: 16px;
  vertical-align: top;
`

const Flex = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RadioBlockField = props => {
  const { input, labelType, text, disabledOption, subText, valueType = 'number', ...rest } = props
  console.log(subText)
  const [id, setId] = useState()
  useEffect(() => {
    setId(Math.random())
  }, [])

  const isImageType = labelType === 'image'

  const handleOnChange = ev => {
    return valueType === 'number' ? input.onChange(+ev.target.value) : input.onChange(ev.target.value)
  }
  const disabled = disabledOption
  useEffect(() => {
    if (disabled) {
      input.onChange(false)
    }
  }, [disabled])
  return (
    <>
      <Label isImageType={isImageType} htmlFor={id}>
        <input
          {...input}
          onClick={disabled ? null : handleOnChange}
          {...rest}
          disabled={disabled ? 'disabled' : null}
          id={id}
        />
        <Payment disabled={disabled}>
          <Flex>
            <Text>{text}</Text>
            <Circle/>
          </Flex>
          {subText ? <SubText>{subText}</SubText> : null}
        </Payment>
      </Label>
    </>
  )
}

export default RadioBlockField

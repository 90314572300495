import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import {X as ExitIcon, X} from 'react-feather'
import AnimationWrapper from "../AnimationWrapper";


const StyledModal = styled('div')`
  color: black;
  margin: 50px;
  position: relative;
  height: calc(100% - 100px);
  top: 0;
  transform: translateY(0) !important;
  ${({ modalStyles }) => modalStyles};
`
const ModalMask = styled('div')`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  pointer-events: auto;
  z-index: 10;
`
const ModalRoot = styled('div')`
  position: relative;
  z-index: ${props => props.zIndex};
  & ${StyledModal} {
    opacity: ${props => props.fadeType === 'in' ? '1' : '0'};
    transform: ${props => props.fadeType === 'in' ? 'scale(1)' : 'scale(0.2)'};
    transition:
      transform ${props => `${props.fadeDuration}ms`} cubic-bezier(0.03, 0.93, 0.44, 0.95),
      opacity ${props => `${props.fadeDuration}ms`};
    transform-origin: center 100px;
    max-width: ${({ maxWidth }) => maxWidth};
    width ${props => props.fullWidth ? '100%' : props.width};
    z-index: 20;
  };
  & ${ModalMask} {
    opacity: ${props => props.fadeType === 'in' ? '1' : '0'};
    transition: all ${props => `${props.fadeDuration}ms`};
  };
`
const ModalWrapper = styled('div')`
  align-items: baseline;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 200px;
  left: 0;
  right: 0;
`
const ModalContent = styled('div')`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(35, 38, 41, 0.5);
  position: relative;
  pointer-events: auto;
`
const TitleWrap = styled('div')`
    min-height: 35px;
    border-bottom: 1px;
    display: flex;
    padding: 18px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(247 247 247);
`

const Title = styled('h4')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${({theme}) => theme.palette.black};
  h2{
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
`

const ExitIconWrap = styled('span')`
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #e6e6e7;
    svg{
        color: rgba(130, 130, 130, 0.85);
    }
`

const SubTitle = styled.p`
  text-align: center;
  margin-top: 15px;
  padding: 0 25px;
  color: ${({ theme }) => theme.color.secondary};
`

const Close = styled('div')`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 36px;
  position: absolute;
  pointer-events: all;
  right: 25px;
  top: 20px;
  transition: all 300ms;
  width: 36px;
  &:hover {
    background-color: #f2f3f4;
  }
  & svg {
    color: #2c3a50;
    font-size: 18px;
    display: block;
  }
`
const ModalBody = styled('div')`
  padding: ${({bodyPadding}) => bodyPadding || '18px 16px 18px'}
`

const Modal = props => {
  const {
    open,
    isRendered,
    onClose,
    title,
    children,
    width,
    maxWidth,
    fullWidth,
    maskClosable,
    fadeType,
    fadeDuration,
    showCloseIcon,
    showHeader,
    subTitle,
    zIndex,
    modalStyles,
    bodyPadding
  } = props

  React.useEffect(() => {
    if (open) document.body.style.overflow = 'hidden'
    else document.body.style = null
  }, [open])

  if (!isRendered) return null

  return (
    <Portal>
      <ModalRoot
        open={open}
        fadeType={fadeType}
        fadeDuration={fadeDuration}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        width={width}
        zIndex={zIndex}
      >
        <ModalWrapper>
          <StyledModal
            modalStyles={modalStyles}
          >
            <ModalContent>
              {showHeader && (
                  <TitleWrap>
                    {title && <Title>{title}</Title>}
                    <ExitIconWrap onClick={onClose}>
                      <ExitIcon size={16} />
                    </ExitIconWrap>
                  </TitleWrap>
              )}
              <ModalBody bodyPadding={bodyPadding}>{children}</ModalBody>
            </ModalContent>
          </StyledModal>
          <ModalMask onClick={maskClosable ? onClose : null} />
        </ModalWrapper>
      </ModalRoot>
    </Portal>
  )
}

Modal.propTypes = {
  fadeType: PropTypes.oneOf([null, 'in', 'out']),
  fadeDuration: PropTypes.number,
  maskClosable: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  isRendered: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  showCloseIcon: PropTypes.bool,
  showHeader: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  width: PropTypes.string,
  zIndex: PropTypes.number
}

Modal.defaultProps = {
  maskClosable: true,
  showCloseIcon: true,
  showHeader: true,
  width: '600px',
  zIndex: 1000
}

export default AnimationWrapper(Modal)

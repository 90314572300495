import React from 'react'
import styled from 'styled-components'
import {map} from 'ramda'
import {Field} from 'react-final-form'
import RadioBlockField from "../../Fields/radio/RadioBlockField";
import {ChevronRight} from "react-feather";

const Title = styled('h4')`
    font-size: ${({theme}) => theme.fontSize.title};
    font-weight: 500;
    margin-bottom: 10px;
`

const Grid = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
`

const Flex = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 3px;
`

const OpenDetail = styled('div')`
  width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    border-radius: 7px;
    cursor: pointer;
`

const AddressesField = ({name, options, title, setDetail,setOpenAddress}) => {
  return (
    <>
      {title && <Title>{title}</Title>}
      <Grid>
        {map(
          ({id, disabled, ...item}) => (
            <Flex>
              <Field
                key={id}
                valueType={'text'}
                disabledOption={disabled}
                value={id}
                defaultValue={'6'}
                id={id}
                {...item}
                component={RadioBlockField}
                type={'radio'}
                name={name}
              />
              <OpenDetail onClick={() => {
                setDetail(item)
                setOpenAddress(true)
              }}>
                <ChevronRight stroke={'#2c3a50'}/>
              </OpenDetail>
            </Flex>
          ),
          options.filter(Boolean)
        )}
      </Grid>
    </>
  )
}

export default AddressesField

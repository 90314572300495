import React from 'react';
import YandexMapField from "../../../Fields/Map/YandexMapField";
import useFetch from "../../../../hooks/useFetch";
import {addressListFetchAction} from "../../../../actions/adress";
import * as STATES from "../../../../redux/reducers/states";
import {path} from "ramda";

const Adress = ({form, values, setOpenAddress, refetch, setDetail}) => {
  const addressList = useFetch({
    action: addressListFetchAction,
    stateName: STATES.GET_ADDRESS,
    onSuccess: ({value}) => {
      form.change('userAddress', (path([0, 'id'], value) || '').toString());
    },
    childIsNull: true,
    dependency: refetch
  })
  return (
    <YandexMapField setOpenAddress={setOpenAddress} addressList={addressList} setDetail={setDetail}/>
  );
};

export default Adress;

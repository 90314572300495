import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import {YMaps, Map, SearchControl, GeolocationControl} from 'react-yandex-maps'
import { drop, join, path, pipe, split } from 'ramda'
import PropTypes from 'prop-types'
import Button from "../../Navigation/Components/Button";
import Pin from "../../SvgIcons/CustomIcons/Pin";
import {useTranslate} from "../../../helpers/translate";

const CENTER = [41.30882292588138, 69.25220409208157];
const API_KEY = 'bc8fdb09-3efc-4819-8ca5-2c1d7f7708d2';

const buttonStyles = {
    marginBottom: '20px',
  zIndex: '2',
};

const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
`;

const PinUI = styled.div`
    position:absolute;
    bottom: calc(50% - 34px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    & svg {
        width: 30px;
        height: 64px;
    }
`;

const AddressBar = styled.div`
    max-width: ${props => props.loading ? '45px' : '700px'};
    transition: max-width 300ms;
    position: absolute;
    z-index: 10;
    bottom: 15px;
    user-select: none;
    padding: 10px 20px;
    background: #fff;
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 4px 15px 0 rgba(190, 195, 204, 0.77);
`;

const LAT = 0;
const LON = 1;

const YandexMap = (props) => {
  const {t} = useTranslate()
  const { input, onToggle, onAddressChange, addressValue } = props;

  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState(null);
  const [address, setAddress] = useState(null);

  const onAddress = (coords) => {
    setLoading(true);
    map.geocode(coords)
        .then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const addressText = firstGeoObject.getAddressLine();
          const withoutCountry = pipe(
              split(','),
              drop(1),
              join(',')
          )(addressText);
          onAddressChange(withoutCountry);

          const location = {
            lat: coords[LAT],
            lon: coords[LON],
          };
          input.onChange(location);
          setLoading(false);
        })
        .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (address && map){
      onAddress(address);
    }
  }, [address])
  const onCenter = (val) => {
    const newCenter = path(['originalEvent', 'newCenter'], val);
    setAddress(newCenter)
  };


  return (
            <MapWrapper>
              <PinUI>
                <Pin />
              </PinUI>
              {!(!addressValue && !loading) && (
                  <AddressBar loading={loading}>
                    {loading ? '...' : addressValue}
                  </AddressBar>
              )}
              <Button
                  onClick={onToggle}
                  title={t('continue')}
                  style={{...buttonStyles}}
              />
              <YMaps query={{ apikey: API_KEY, lang: 'ru_RU', mode: 'debug' }} preload={true}>
                <Map
                    height={'100%'}
                    width={'100%'}
                    onLoad={setMap}
                    onBoundsChange={onCenter}
                    modules={['geocode']}
                    defaultState={{ center: CENTER, zoom: 12, controls: [] }}
                >
                  <SearchControl options={{ float: 'left' }} />
                  <GeolocationControl options={{ float: 'left' }} />
                </Map>
              </YMaps>
      </MapWrapper>
  );
};

YandexMap.propTypes = {
  input: PropTypes.any,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  onAddressChange: PropTypes.func,
  addressValue: PropTypes.any,
};

export default YandexMap;

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {path} from 'ramda'
import TextField from "../../Fields/Text";
import Button from "../../Navigation/Components/Button";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {Field} from "react-final-form";
import Loader from "../../Loader";

const CENTER = [41.30882292588138, 69.25220409208157];
const API_KEY = 'bc8fdb09-3efc-4819-8ca5-2c1d7f7708d2';

const LocationBlock = styled.div`
    display: grid;
    min-width: 160px;
    align-items: center;
    grid: 1fr / min-content 1fr;
    grid-gap: 7px;
`
const LocationText = styled.div`
    font-weight: 500;
    font-size: 16px;
`
const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
    font-size: ${({theme}) => theme.fontSize.capitalTwo};
    line-height: 164.57%;
    color: ${({theme}) => theme.color.primaryGray};
    margin: 0 0 15px 0;

    &:after {
        content: ${({suffix}) => suffix ? `"${suffix} *"` : ''};
        display: inline-block;
        color: #818591;
        font-size: 13px;
        font-weight: 400;
        margin-left: 10px;
        position: relative;
        bottom: 8px;
    }
`


const YandexWrap = styled.div`
    position: relative;
    margin-bottom: 20px;
`

const YandexCont = styled.div`
    overflow-x: hidden;
`

const YandexEdit = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
`

const Wrapper = styled.div`
    position: relative;
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid: 1fr / 1fr;
    grid-gap: 10px;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`


const ChangeAddress = styled.div`
    position: relative;
    cursor: pointer;
    margin: 0 0 13px 0;

    &:after {
        content: " ";
        position: absolute;
        bottom: -2px;
        left: 0px;
        height: 2px;
        background-color: #111;
        transform-origin: 0% 100%;
        transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;;
        width: ${({isActive}) => isActive ? '100%' : '0px'};
    }
;

    &:hover {
        &:after {
            width: 100%;
        }
    ;
    }
;
`

const Grid = styled('div')`
    display: grid;
    grid-template-columns: repeat(3, 98px);
    gap: 5px;
`

const AddressField = props => {
  const {
    onIconClick,
    loading,
    withFloat,
    locationInputs,
    name,
    label = 'Введите или выберите адрес на карте',
    isDetail,
    ...rest
  } = props
  const coords = path(['value'], locationInputs)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <YandexCont>
        {isDetail ? (
          <Button
            style={{
              marginBottom: '20px',
            }}
            theme={'delete'}
            type={'submit'}
            loading={isLoading || loading}
            disabled={isLoading || loading}
            title={'Удалить'}
          />
        ) : (
          <Button
            style={{
              marginBottom: '20px',
            }}
            type={'submit'}
            loading={isLoading || loading}
            disabled={isLoading || loading}
            title={'Продолжить'}
          />
        )}
        {isLoading || loading
          ? (
            <Loader/>
          ) : (
            <>
              <YandexWrap>
                {!isDetail && <YandexEdit>
                  <Button title={'Изменить'} onClick={onIconClick} width={'150px'} type={'button'}/>
                </YandexEdit>}
                <YMaps query={{apikey: API_KEY, lang: 'ru_RU'}} preload={true}>
                  <Map
                    height={'160px'}
                    width={'100%'}
                    modules={['geocode']}
                    defaultState={{center: [coords.lat, coords.lon], zoom: 15, controls: []}}
                  >
                    <Placemark
                      geometry={[coords.lat, coords.lon]}
                    />
                  </Map>
                </YMaps>
              </YandexWrap>
              <div>
                <Field
                  component={TextField}
                  placeholder={'Адрес'}
                  name={'name'}
                  disabled={isDetail}
                />
                <Grid>
                  <Field
                    component={TextField}
                    name={'entrance'}
                    placeholder={'Подьезд'}
                    disabled={isDetail}
                  />
                  <Field
                    component={TextField}
                    name={'floor'}
                    placeholder={'Этаж'}
                    disabled={isDetail}
                  />
                  <div style={{marginBottom: '16px'}}>
                    <Field
                      component={TextField}
                      name={'room'}
                      placeholder={'Кв./Офис'}
                      disabled={isDetail}
                    />
                  </div>
                </Grid>
                <Field
                  component={TextField}
                  name={'comment'}
                  placeholder={'Комментарий'}
                  disabled={isDetail}
                />
              </div>
            </>
          )}
      </YandexCont>
    </>
  )
}

AddressField.propTypes = {
  onIconClick: PropTypes.func,
  input: PropTypes.any,
  withFloat: PropTypes.bool
}

export default AddressField
